<template>
  <div v-if="!checkCookieActive()">
    <h2>Error</h2>
    <p>
      First-Party cookies are required for full site functionality. Please enable First-Party cookies and try again.
    </p>
    <div class="btn-actions">
      <CustomButton
        :extra-classes="['error-control-btn', 'delete-reject']"
        style-name="secondary"
        @click-button="() => handleRefresh('close')"
      >
        Cancel
      </CustomButton>
      <CustomButton
        :extra-classes="['error-control-btn', 'delete-confirm']"
        @click-button="() => handleRefresh('reload')"
      >
        Reload
      </CustomButton>
    </div>
  </div>
  <div v-else-if="goodJwt">
    <h2>{{ truncatedError }}</h2>
    <p>There was an issue with your cart. Please refresh the cart, or reload the page and try again.</p>
    <p>Refreshing the cart will remove all items.</p>
    <div class="btn-actions">
      <CustomButton
        :extra-classes="['error-control-btn', 'delete-reject']"
        style-name="secondary"
        @click-button="() => handleRefresh('reload')"
      >
        Reload
      </CustomButton>
      <CustomButton
        :extra-classes="['error-control-btn', 'delete-confirm']"
        @click-button="() => handleRefresh('confirm-delete')"
      >
        Refresh
      </CustomButton>
    </div>
  </div>
  <div v-else>
    <h2>Refresh Cart?</h2>
    <p>Your cart needs to be refreshed before you can continue. This will remove all items from the cart.</p>
    <p>Do you want to refresh now?</p>
    <div class="btn-actions">
      <CustomButton
        :extra-classes="['error-control-btn', 'delete-reject']"
        style-name="secondary"
        @click-button="() => handleRefresh('close')"
      >
        Cancel
      </CustomButton>
      <CustomButton
        :extra-classes="['error-control-btn', 'delete-confirm']"
        @click-button="() => handleRefresh('confirm-delete')"
      >
        Refresh
      </CustomButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { truncateError } from '~/util/error';
import { useCartStore } from '~/store/cart';

type ModalBodyErrorClosetype = 'emit-close';

const cartStore = useCartStore();
const emit = defineEmits(['emit-close']);

const props = defineProps({
  cartError: {
    type: Object as () => any,
    default: () => '' as any,
  },
  emitClose: {
    type: String as () => ModalBodyErrorClosetype,
    default: 'emit-close',
  },
});

const goodJwt = computed(() => {
  const JWTErrors = ['invalid compact jws', 'bad cart token'];
  const error = (props.cartError?.stack || props.cartError?.message || props.cartError)?.toLowerCase();
  const checker = JWTErrors.some((subStr) => error?.includes(subStr));
  return !checker;
});
const truncatedError = computed(() => truncateError(props.cartError));

function handleRefresh(refreshMode = '') {
  switch (refreshMode) {
    case 'close':
      emit(props.emitClose, false);
      break;
    case 'confirm-delete':
      cartStore.resetCart();
      emit(props.emitClose, false);
      window.location.reload();
      break;
    case 'reload':
      window.location.reload();
      break;
  }
}
function checkCookieActive() {
  return navigator?.cookieEnabled || false;
}
</script>

<style lang="scss" scoped>
p {
  color: initial;
}
.btn-actions {
  height: 100%;
  display: flex;
  justify-content: space-around;

  .error-control-btn {
    width: 45%;
    max-width: #{local-functions.rem-calc(310)};
  }

  .delete-reject {
    border: 2px solid $color-secondary-600;
    color: $color-secondary-600;
  }

  .delete-confirm {
    background-color: $color-danger-default;
    border: 2px solid $color-danger-default;
  }
}
</style>
